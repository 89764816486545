:root {
  background-color: rgba(5, 46, 20, 0.743);
  background-repeat: no-repeat;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin-left: 7%;
  margin-right: 7%;
  background-color: #b7bab1;
  border-radius: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 1% 2% 2% 2%;
  font-size: max(1.5vw, 10px);
}

header {
  padding-bottom: 1%;
  display: inline-flex;
  align-items: center;
  grid-gap: 2%;
  gap: 2%;
  width: 100%;
  border-bottom: solid 1px;
}

a {
  text-decoration: none;
}

h2 {
  color: #18340a;
  font-style: italic;
}

h2:hover {
  color: #23122d;
}

#profiles {
    padding-top: 2%;
    margin-top: 2%;
    border-top: solid 1px;
    display: flex;
    justify-content: center;
    grid-gap: 10%;
    gap: 10%;
    flex-wrap: wrap;
}
#about-us {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    background-color: #b7bab1;
    padding-bottom: 2%;
    border-bottom: solid 1px;
}

@media screen and (max-width: 500px) {
    #about-us {
        flex-direction: column;
    }

    div > #about-us-text {
        width: 100%;
    }
}

#about-us-text {
    width: 50%
}
.logo {
    display: block;
    width: max(3.5vw, 30px);
    height: max(3.5vw, 30px);
}



#ccb-insta:hover {
    scale: 1.1 1.1;
}

a::before, a:visited {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #cecece;
    width: 15%;
    min-width: 150px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px gray;
}

.dropdown-content a {
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    color: black;
    text-decoration: none;
}

.dropdown:hover
.dropdown-content {
    display: block;
}

.dropdown:hover
.logo {
    -webkit-animation: rotation 1.2s;
            animation: rotation 1.2s;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    } to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.dropdown-content a:hover {
    background-color: #9ecda3;
    border-radius: 10px;
}
.profile {
    border-radius: 5px;
    height: max(220px, 30vw);
    background-color: #e2e6c050;
    border: 1px solid;
}

.image:hover {
    border-radius: 10px;
    scale: 1.06 1.06;
}

a::before ,a:visited {
    text-decoration: none;
    color: black;
    cursor: pointer;
}
.page-section {
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    background-color: #e2e6c050;
    box-shadow: 3px 3px 3px gray;
}

.intro-image {
    width: 20%;
}

.intro-text {
    width: 80%;
    margin-left: 5%;
}

.intro:hover
.intro-image {
    border-radius: 10px;
    scale: 1.06 1.06;
}
.images {
    width: 20%;
    box-sizing: border-box;
    object-fit: cover;
    border: 1px solid;
}

.images:active{
    scale: 2;
    object-fit: contain;
    border: none;
}

.pictureList {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 500px) {
    .images {
        width: 30%;
    }

    .pictureList {
        justify-content: center;
    }
}
