.images {
    width: 20%;
    box-sizing: border-box;
    object-fit: cover;
    border: 1px solid;
}

.images:active{
    scale: 2;
    object-fit: contain;
    border: none;
}

.pictureList {
    display: flex;
    flex-wrap: wrap;
}

@media screen and (max-width: 500px) {
    .images {
        width: 30%;
    }

    .pictureList {
        justify-content: center;
    }
}