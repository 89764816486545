.logo {
    display: block;
    width: max(3.5vw, 30px);
    height: max(3.5vw, 30px);
}



#ccb-insta:hover {
    scale: 1.1 1.1;
}

a::before, a:visited {
    text-decoration: none;
    color: black;
    cursor: pointer;
}