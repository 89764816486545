.dropdown-content {
    display: none;
    position: absolute;
    background-color: #cecece;
    width: 15%;
    min-width: 150px;
    border-radius: 10px;
    box-shadow: 3px 3px 3px gray;
}

.dropdown-content a {
    padding-left: 10%;
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
    color: black;
    text-decoration: none;
}

.dropdown:hover
.dropdown-content {
    display: block;
}

.dropdown:hover
.logo {
    animation: rotation 1.2s;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}

.dropdown-content a:hover {
    background-color: #9ecda3;
    border-radius: 10px;
}