#about-us {
    display: flex;
    flex-direction: row;
    padding-top: 2%;
    background-color: #b7bab1;
    padding-bottom: 2%;
    border-bottom: solid 1px;
}

@media screen and (max-width: 500px) {
    #about-us {
        flex-direction: column;
    }

    div > #about-us-text {
        width: 100%;
    }
}

#about-us-text {
    width: 50%
}