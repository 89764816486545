.profile {
    border-radius: 5px;
    height: max(220px, 30vw);
    background-color: #e2e6c050;
    border: 1px solid;
}

.image:hover {
    border-radius: 10px;
    scale: 1.06 1.06;
}

a::before ,a:visited {
    text-decoration: none;
    color: black;
    cursor: pointer;
}