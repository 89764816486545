:root {
  background-color: rgba(5, 46, 20, 0.743);
  background-repeat: no-repeat;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  margin-left: 7%;
  margin-right: 7%;
  background-color: #b7bab1;
  border-radius: 10px;
  height: fit-content;
  padding: 1% 2% 2% 2%;
  font-size: max(1.5vw, 10px);
}

header {
  padding-bottom: 1%;
  display: inline-flex;
  align-items: center;
  gap: 2%;
  width: 100%;
  border-bottom: solid 1px;
}

a {
  text-decoration: none;
}

h2 {
  color: #18340a;
  font-style: italic;
}

h2:hover {
  color: #23122d;
}